const { window } = global;

const getSheetsDSN = () => {
  if (window?.isReport) {
    return 'https://d69c7dc71f6243c19aa8f1466c32ab5c@o61806.ingest.sentry.io/5352078';
  }

  if (window?.isBoards) {
    return 'https://983ae60052ddc6cf8a60dca2bfaa3b8c@o61806.ingest.sentry.io/4507571577749504';
  }

  // Sheets
  return 'https://23de99eacf6c4a068ba11e1a39556e6a@o61806.ingest.sentry.io/1211430';
};

const getFSEnabledForSignFiller = () => {
  try {
    return window?.parent?.fsEnabled;
  } catch (e) {
    return false;
  }
};

module.exports = {
  academy: {
    sentry: {
      enable: true,
      dsn: 'https://e21856d505644c643840103d8dbd250c@o61806.ingest.sentry.io/4506626385641472'
      // name: 'academy',
    }
  },
  checkout: {
    sentry: {
      enable: true,
      dsn: 'https://ca58c76e1157458f941020590eab787c@o61806.ingest.sentry.io/4505396476641280'
    },
    fullStory: {
      enable: true
    }
  },
  'data-dashboards': {
    sentry: {
      enable: true,
      dsn: 'https://64a93c3dc440e48a9118dc2c169351ef@o61806.ingest.us.sentry.io/4507407451160576'
    }
  },
  'email-builder': {
    sentry: {
      enable: true,
      dsn: 'https://6625f7b197c65a4d8e49bc8e7641c8c2@o61806.ingest.us.sentry.io/4507407576465408'
    }
  },
  'email-details': {
    sentry: {
      enable: true,
      dsn: 'https://e0a74287bf15c46b981ddad77de5d7d7@o61806.ingest.sentry.io/4505822059823104'
    },
    fullStory: {
      enable: false
    }
  },
  'form-builder': {
    sentry: {
      enable: true,
      dsn: 'https://7b39e767f39941dd89d9361cf50382dd@o61806.ingest.sentry.io/132624',
      name: 'jotform-40'
    }
  },
  inbox: {
    sentry: {
      enable: true,
      dsn: 'https://f5fc3cd88f0744df9e3c8a263cd00db1@o61806.ingest.sentry.io/1353659'
      // name: 'inbox'
    },
    fullStory: {
      enable: window?.isFullStoryEnabled
    }
  },
  'interview-management': {
    sentry: {
      enable: true,
      dsn: 'https://f875f9d7646f4149a6bfff821534e23b@o61806.ingest.sentry.io/4504971807162368'
    }
  },
  listings: {
    sentry: {
      enable: true,
      dsn: 'https://2c715cb8ed4745c383ff6830ca885807@o61806.ingest.sentry.io/5975209',
      name: 'listings'
    },
    fullStory: {
      enable: window?.isFullStoryEnabled
    }
  },
  myaccount: {
    sentry: {
      enable: true,
      dsn: 'https://4048665e726d45eba296f250ad4d09bb@o61806.ingest.sentry.io/6396369'
      // name: 'myaccountv4'
    },
    fullStory: {
      enable: window?.fullstoryEnabled
    }
  },
  'page-builder': {
    sentry: {
      enable: true,
      dsn: 'https://727c1f481e28485a97e8a4ddae1bdaab@o61806.ingest.sentry.io/6179928'
      // name: 'landing-builder'
    }
  },
  'pdf-editor': {
    sentry: {
      enable: window?.location.href.indexOf('download') < 0,
      dsn: 'https://10dcd48960b74f9e84c93bee96ee2c9e@o61806.ingest.sentry.io/1234288'
      // name: 'pdf-designer'
    },
    fullStory: {
      enable: window?.isFullStoryEnabled
    }
  },
  portal: {
    sentry: {
      enable: true,
      dsn: 'https://36d29a013f8a4bd7adfe310f5fe4d75a@o61806.ingest.sentry.io/5223511',
      name: 'app-builder'
    },
    fullStory: {
      enable: window?.fullStoryActiveForPortal
    }
  },
  'refund-request': {
    sentry: {
      enable: true,
      dsn: 'https://c2bf244d1db8d74e25fafaf1b8f70747@o61806.ingest.sentry.io/4505786346110976'
    },
    fullStory: {
      enable: true
    }
  },
  sheets: {
    sentry: {
      enable: true,
      dsn: getSheetsDSN()
      // name: ['reports', 'spreadsheets']
    }
  },
  sign: {
    sentry: {
      enable: true,
      dsn: 'https://9b0abbc9280c47c683b8a9067f61e88e@o61806.ingest.sentry.io/5680940'
      // name: 'sign'
    },
    fullStory: {
      enable: window?.fsEnabled
    }
  },
  'sign-filler': {
    sentry: {
      enable: true,
      dsn: 'https://1e23807c9020c755e635a09392e97b22@o61806.ingest.us.sentry.io/4507339316985856'
    },
    fullStory: {
      enable: getFSEnabledForSignFiller()
    }
  },
  'subscription-dashboard': {
    sentry: {
      enable: true,
      dsn: 'https://75f5c88aeffd4f54964275c0fba96d59@o61806.ingest.sentry.io/6190171'
      // name: 'subscription-dashboard'
    }
  },
  workflow: {
    sentry: {
      enable: true,
      dsn: 'https://15571fc5437e4368b4e2ed0e32c6d93f@o61806.ingest.sentry.io/5261296'
      // name: 'workflow'
    },
    fullStory: {
      enable: !window?.isTemplateMode
    }
  },
  'ai-agent': {
    sentry: {
      enable: true,
      dsn: 'https://9595e22b6185cd8e2ff3ea2fc28cc68f@o61806.ingest.us.sentry.io/4507502561001472',
      name: 'ai-agent'
    }
  },
  'ai-agent-builder': {
    sentry: {
      enable: true,
      dsn: 'https://59098a0cef7524a00cbc88db5b10e2f5@o61806.ingest.us.sentry.io/4507883422875648'
    },
    fullStory: {
      enable: window?.fsEnabled
    }
  }
};
