import React from 'react';
import { func, string } from 'prop-types';

import { Texts } from '@jotforminc/constants';
import { t, translationRenderer } from '@jotforminc/translation';

import { IconTrashExclamationFilled } from '@jotforminc/svg-icons';

const DeleteAccountModalStep1 = ({ handleStep, username }) => {
  return (
    <div className='delete-account-modal-step-1'>
      <div className='delete-step-1-body'>
        <div className='delete-step-1-icon-wrapper'>
          <div className='delete-step-1-icon-container'>
            <IconTrashExclamationFilled />
          </div>
        </div>
        <h4>{t(Texts.ARE_YOU_SURE_YOU_WANT_TO_DELETE_YOUR_ACCOUNT)}</h4>
        <p>
          {' '}
          {t(Texts.YOU_ARE_ABOUT_TO_DELETE_YOUR_ACCOUNT_NAMELY)}
          {' '}
          <span>{username}</span>
        </p>
        <p>
          {translationRenderer(Texts.GET_IN_TOUCH_WITH_US)({
            renderer1: text => (
              <a href='/answers' target='_blank'>{text}</a>
            )
          })}
        </p>
      </div>
      <div className='delete-step-1-footer'>
        <button className='btn btn-red' type="button" onClick={() => handleStep(2)}>{t(Texts.YES_DELETE)}</button>
        <button className='btn btn-green' type="button" onClick={() => handleStep(0)}>{t(Texts.CANCEL)}</button>
      </div>
    </div>
  );
};

DeleteAccountModalStep1.propTypes = {
  handleStep: func.isRequired,
  username: string.isRequired
};

export default DeleteAccountModalStep1;
