import React, { useState, useImperativeHandle } from 'react';
import {
  array, bool, func, object
} from 'prop-types';

import DeleteAccountModalStep1 from './DeleteAccountModalStep1';
import DeleteAccountModalStep2 from './DeleteAccountModalStep2';
import DeleteAccountModalStep3 from './DeleteAccountModalStep3';

import '../../styles/verification-modal.scss';
import '../../styles/delete-my-account-modal.scss';

const DeleteAccountModal = React.forwardRef(({
  socialLogins, onAppleLoginClick, onFBLoginClick, onGoogleLoginClick, onMicrosoftLoginClick, onSalesforceLoginClick, thereSocialError, user, handleClose
}, ref) => {
  const [step, setStep] = useState(1);

  const { credentials: { username } } = user;

  const handleStep = nextStep => {
    setStep(nextStep);
    if (nextStep === 0) {
      handleClose();
    }
  };

  useImperativeHandle(ref, () => (
    {
      handleStep
    }
  ));

  return (
    <>
      {step === 1 && (
        <DeleteAccountModalStep1 handleStep={handleStep} username={username} />
      )}
      {step === 2 && (
        <DeleteAccountModalStep2
          socialLogins={socialLogins} onAppleLoginClick={onAppleLoginClick} onFBLoginClick={onFBLoginClick}
          onGoogleLoginClick={onGoogleLoginClick} onMicrosoftLoginClick={onMicrosoftLoginClick} onSalesforceLoginClick={onSalesforceLoginClick}
          thereSocialError={thereSocialError}
          user={user} handleStep={handleStep}
        />
      )}
      {step === 3 && (
        <DeleteAccountModalStep3 username={username} />
      )}
    </>
  );
});

DeleteAccountModal.propTypes = {
  socialLogins: array,
  onAppleLoginClick: func,
  onFBLoginClick: func,
  onGoogleLoginClick: func,
  onMicrosoftLoginClick: func,
  onSalesforceLoginClick: func,
  thereSocialError: bool,
  user: object,
  handleClose: func
};

DeleteAccountModal.defaultProps = {
  socialLogins: [],
  onAppleLoginClick: () => {},
  onFBLoginClick: () => {},
  onGoogleLoginClick: () => {},
  onMicrosoftLoginClick: () => {},
  onSalesforceLoginClick: () => {},
  thereSocialError: false,
  user: {},
  handleClose: () => {}
};

export default DeleteAccountModal;
