/* eslint-disable max-len */
/* eslint-disable max-statements */
import React, {
  forwardRef, useImperativeHandle, useState, useRef, useEffect
} from 'react';
import {
  bool, func, shape, string
} from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import '../../styles/style.scss';
import { IconEyeSlashFilled, IconEyeFilled } from '@jotforminc/svg-icons';
import { checkPasswordReuse, fetchUserCredentialRuleSet } from '../../utils/helper';
import { ScPassword } from './scPassword';
import RequirementsList from './RequirementsList';
import { REGULAR_VALIDATOR_RULE_DEFAULTS, SECURE_VALIDATOR_RULE_DEFAULTS } from './constants';
import ErrorAlert from '../ErrorAlert';

// eslint-disable-next-line complexity
const Password = forwardRef(({
  getCurrentPasswordValue,
  hasForgotPassUI,
  forgotPassFunc,
  passwordLabel,
  passwordConfirmationLabel,
  secure,
  secureDisplay,
  toggleSecureDisplay,
  checkReuse,
  includeConfirm,
  onKeyDown,
  passwordErrorServer,
  resetPasswordError,
  setSecurePaswordError,
  callbackConfirmPassError,
  userType,
  requestCurrentPassword,
  allowPasswordShow,
  onChange,
  onPasswordInputBlur,
  sendValueToParent,
  initialValue,
  clearInitialOnFocus,
  hasErrorMessage,
  hasSecureTextIcon,
  className,
  disclaimer,
  autoComplete,
  onPasswordValidate,
  isOnboardingFlow,
  customTexts,
  hideNeutralRules
}, ref) => {
  const basicRuleMessages = { required: t(Texts.FIELD_REQUIRED) };
  if (includeConfirm) Object.assign(basicRuleMessages, { shouldMatch: t(Texts.FIELDS_SHOULD_MATCH) });
  const allRuleMessagesRef = useRef({ ...basicRuleMessages });
  const inputPassRef = useRef();
  const inputConfirmPassRef = useRef();
  const inputCurrentPassRef = useRef();

  const initialState = {
    passwordMode: 'hide',
    securePass: {},
    regularPass: {}
  };

  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [password, setPassword] = useState(initialValue);
  const [isCleared, setCleared] = useState(false); // Only works with clearInitialOnFocus prop
  const [isChanged, setChanged] = useState(false); // Only works with clearInitialOnFocus prop
  const [confirmPassword, setConfirmPassword] = useState('');
  const [requiredPassError, setRequiredPassError] = useState('');
  const [confirmPassError, setConfirmPassError] = useState('');
  const [passwordMode, setPasswordMode] = useState(initialState.passwordMode);

  // rules that is visible only if matches
  const [passwordSecurityRules, setPasswordSecurityRules] = useState();

  // rules
  const regularValidatorRules = useRef(REGULAR_VALIDATOR_RULE_DEFAULTS);
  const secureValidatorRules = useRef(SECURE_VALIDATOR_RULE_DEFAULTS);

  // pass errors
  const [regularPassErrors, setRegularPassErrors] = useState(initialState.regularPass);
  const [securePassErrors, setSecurePassErrors] = useState(initialState.securePass);
  const [passwordError, setPasswordError] = useState();

  // rule messages
  const [regularRuleMessages, setRegularRuleMessages] = useState();
  const [secureRuleMessages, setSecureRuleMessages] = useState();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();

  // requirements list
  const [showRegularRequirementsList, setShowRegularRequirementsList] = useState(false);
  const [showSecureRequirementsList, setShowSecureRequirementsList] = useState(!toggleSecureDisplay);

  const [requirementDescText, setRequirementDescText] = useState('');

  useEffect(() => {
    setPasswordError(passwordErrorServer ? { passwordErrorServer: false } : null);
    setPasswordErrorMessage(passwordErrorServer ? { passwordErrorServer } : null);
  }, [passwordErrorServer]);

  useEffect(() => {
    const initPasswordRuleSet = rules => {
      let errorTypes = {};
      let nextMessages = {};

      rules.forEach(({ rule_name: ruleName, error_message: errorMessage }) => {
        errorTypes = { ...errorTypes, [ruleName]: null };
        nextMessages = { ...nextMessages, [ruleName]: t(errorMessage) };
      });

      Object.assign(allRuleMessagesRef.current, { [secure ? 'secureRules' : 'regularRules']: nextMessages });

      if (secure && checkReuse) {
        setSecureRuleMessages({ ...nextMessages, reuse: t(Texts.SECURE_PASS_REUSE) });
        setSecurePassErrors({ ...errorTypes });
      } else if (secure) {
        setSecureRuleMessages({ ...nextMessages });
        setSecurePassErrors({ ...errorTypes });
      } else {
        setRegularRuleMessages({ ...nextMessages });
        setRegularPassErrors({ ...errorTypes });
      }
    };

    const constructPasswordRules = credentialRules => {
      const passwordSecurityRuleNames = {};
      const passwordRuleData = credentialRules.find(({ field_name: fieldName }) => fieldName === 'password');
      const passwordSecurityRuleData = credentialRules.find(({ field_name: fieldName }) => fieldName === 'password_security');

      passwordSecurityRuleData?.rules.forEach(rule => {
        passwordSecurityRuleNames[rule.rule_name] = true;
      });

      setPasswordSecurityRules({ ...passwordSecurityRuleNames });

      return [
        passwordRuleData.explanation,
        [
          ...passwordRuleData.rules,
          ...(passwordSecurityRuleData?.rules || [])
        ]
      ];
    };

    const getPasswordRuleSet = async () => {
      try {
        const credentialRules = await fetchUserCredentialRuleSet(secure);
        const [explanation, rules] = constructPasswordRules(credentialRules);

        if (!secure) {
          regularValidatorRules.current = rules;
        } else {
          secureValidatorRules.current = rules;
        }

        setRequirementDescText(t(explanation));
        initPasswordRuleSet(rules);
      } catch (error) {
        setRequirementDescText(t(Texts.SECURE_PASS_TEST_DESC));
        const defaultRules = secure ? SECURE_VALIDATOR_RULE_DEFAULTS : REGULAR_VALIDATOR_RULE_DEFAULTS;
        initPasswordRuleSet(defaultRules);
      }
    };

    getPasswordRuleSet();
  }, []);

  const validateSomeRules = (value, ruleSet) => {
    return ruleSet.reduce((prevRules, { rule_name: ruleName, regex }) => {
      return {
        ...prevRules,
        [ruleName]: (new RegExp(regex)).test(value)
      };
    }, {});
  };

  const handleRequirementsListView = (reuseChecked = false) => {
    if (toggleSecureDisplay && password) {
      // from props
      onPasswordInputBlur();

      // add this feature for hipaa wizard
      let hasError = false;
      Object.keys(securePassErrors).forEach(key => {
        if (
          key !== (!reuseChecked ? 'reuse' : '')
          && !securePassErrors[key]
        ) {
          hasError = true;
        }
      });

      setShowSecureRequirementsList(hasError);
    }
  };

  const backenDefinedRulesHasError = current => {
    // A/B Test: signupOnboardingIV
    const defaultChecker = val => !val; // null, false, undefined etc. will be counted as hasError is true
    const abTestChecker = val => val === false; // we want it to be exactly false, because initially is null and we don't want it to be counted as hasError is true
    let checker = isOnboardingFlow ? abTestChecker : defaultChecker;
    if (hideNeutralRules) checker = val => val === false;

    let hasError = false;
    Object.keys(current).forEach(key => {
      if (key && checker(current[key])) {
        hasError = true;
      }
    });
    return hasError;
  };

  const validateCurrentPassword = () => {
    if (!currentPassword) {
      setCurrentPasswordError(t(Texts.FIELD_REQUIRED));
      return false;
    }
    setCurrentPasswordError(false);
    return true;
  };

  const validatePassword = () => {
    let hasProblem = false;
    Object.entries(allRuleMessagesRef.current)
      .forEach(([rule, errorMessage]) => {
        switch (rule) {
          case 'required':
            if (!password) {
              setRequiredPassError(errorMessage);
              setSecurePaswordError(['passwordReqired']);
              hasProblem = true;
            } else {
              setRequiredPassError('');
            }
            break;
          case 'regularRules': {
            const validatedRegulars = validateSomeRules(password, regularValidatorRules.current);
            setRegularPassErrors({ ...validatedRegulars });
            break;
          }
          case 'secureRules': {
            // 'reuse' is handled later.
            const validatedSecures = validateSomeRules(password, secureValidatorRules.current);
            setSecurePassErrors({ ...validatedSecures });
            break;
          }
          default:
            break;
        }
      });
    return hasProblem;
  };

  const validateConfirmPass = () => {
    let hasProblem = false;
    if (!includeConfirm) return hasProblem;

    Object.entries(allRuleMessagesRef.current)
      .forEach(([rule, errorMessage]) => {
        switch (rule) {
          case 'required':
            if (!confirmPassword) {
              setConfirmPassError(errorMessage);
              hasProblem = true;
              callbackConfirmPassError('confirmPasswordRequired');
            } else {
              setConfirmPassError('');
              hasProblem = false;
              callbackConfirmPassError('');
            }
            break;
          case 'shouldMatch':
            if (password && confirmPassword) {
              const match = password === confirmPassword;
              setConfirmPassError(!match ? errorMessage : '');
              callbackConfirmPassError(!match ? 'shouldMatch' : '');
              hasProblem = !match;
            }
            break;
          default:
            break;
        }
      });
    return hasProblem;
  };

  const makeAllValidations = async () => {
    if (requestCurrentPassword) {
      const currPassValid = validateCurrentPassword();
      if (!currPassValid) {
        return { isValid: currPassValid };
      }
    }

    const passHasProblem = validatePassword();
    let isValid = !passHasProblem;

    if (!secure) {
      const regularRulesHasProblem = Object.entries(regularPassErrors)
        .filter(([, val]) => !val).length > 0;
      isValid = isValid && !regularRulesHasProblem;
    }

    const confPassHasProblem = validateConfirmPass();
    if (includeConfirm) {
      isValid = isValid && !confPassHasProblem;
    }

    if (!secure) {
      return {
        isValid,
        password,
        currentPassword
      };
    }

    const secureHasProblem = secure ? Object.entries(securePassErrors)
      .filter(([term]) => term !== 'reuse') // Will be checked manually.
      .filter(([, val]) => !val).length > 0
      : false;

    isValid = isValid && !secureHasProblem;

    if (!isValid || !checkReuse) { // if not valid by now, no need to check reuse.
      return {
        isValid,
        password,
        currentPassword
      };
    }

    // Lets make last remaining validation
    let isReuseAttempt = false;
    try {
      const { content } = await checkPasswordReuse(password, userType);
      isReuseAttempt = content;
    } catch (err) {
      isReuseAttempt = err?.data?.message;
    }

    setSecurePassErrors({
      ...securePassErrors,
      reuse: !isReuseAttempt
    });

    handleRequirementsListView(true);

    return {
      isValid: isValid && !isReuseAttempt,
      password,
      currentPassword
    };
  };

  const handlePasswordChange = e => {
    const { value } = e.target;
    setPassword(value);
    if (sendValueToParent) {
      onChange(value);
    }
    if (clearInitialOnFocus) {
      setChanged(true);
    }
  };

  const handleFocus = () => {
    if (clearInitialOnFocus && !isCleared) {
      setPassword('');
      setCleared(true);
    }
    // getUserVariant();
  };

  const handleBlur = () => {
    if (clearInitialOnFocus && isCleared && !isChanged) {
      setPassword(initialValue);
      setCleared(false);
    }

    handleRequirementsListView();
  };

  const handleCurrentPasswordChange = e => {
    const { value } = e.target;
    setCurrentPassword(value);
    getCurrentPasswordValue(value);
  };

  const handleConfirmPasswordChange = e => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const handlePasswordModeToggle = inputRef => {
    inputRef.current.focus(); // Use here to prevent flickring keyboard on mobile devices
    setPasswordMode(passwordMode === 'hide' ? 'show' : 'hide');
  };

  useImperativeHandle(ref, () => ({
    validate: makeAllValidations,
    inputRef: inputPassRef
  }));

  const getSecureRuleColor = status => {
    const colors = {
      default: '#8d8fa8',
      defaultWithIcon: '#2B3245',
      item: {
        success: '#01bd6f',
        error: '#f23a3c'
      },
      reuse: {
        success: '#2B3245',
        error: '#f23a3c'
      }
    };

    if (hasSecureTextIcon && status.type !== 'reuse') return colors.defaultWithIcon;
    if (status.value === null) return colors.default;

    return status.value ? colors[status.type].success : colors[status.type].error;
  };

  const getSecureRuleClass = status => {
    if (status === null) return 'isNeutral';
    return status ? 'isGood' : 'isBad';
  };

  const renderSecureRequirementsList = () => (
    <RequirementsList
      checkReuse={checkReuse}
      securePassText={t(Texts.SECURE_PASS_REUSE)}
      securePassContText={t(Texts.SECURE_PASS_REUSE_CONT)}
      securePassDescText={customTexts.secureRequirementDesc || t(Texts.SECURE_PASS_DESC)}
      securePassErrors={passwordError || securePassErrors}
      getSecureRuleClass={getSecureRuleClass}
      getSecureRuleColor={getSecureRuleColor}
      secureRules={passwordErrorMessage || secureRuleMessages}
      hasSecureTextIcon={hasSecureTextIcon}
      passwordSecurityRules={passwordSecurityRules}
    />
  );

  Hooks.useEffectIgnoreFirst(() => {
    validatePassword();
    if (password && confirmPassword) {
      validateConfirmPass();
    }
    resetPasswordError();
  }, [password]);

  Hooks.useEffectIgnoreFirst(() => {
    validateConfirmPass();
  }, [confirmPassword]);

  Hooks.useEffectIgnoreFirst(() => {
    const regularPassHasError = backenDefinedRulesHasError(regularPassErrors);
    const securePassHasError = backenDefinedRulesHasError(securePassErrors);

    setShowRegularRequirementsList(passwordError || regularPassHasError);
    setShowSecureRequirementsList(passwordError || securePassHasError);
    onPasswordValidate({ passwordError, regularPassErrors, securePassErrors });
  }, [passwordError, regularPassErrors, securePassErrors]);

  return (
    <ScPassword className={className} ref={ref}>
      {requestCurrentPassword && (
        <div className="xcl-field-wr forPassword">
          <label className="xcl-lbl" htmlFor="currPass">{t(Texts.CURRENT_PASSWORD)}</label>
          <input
            className={`xcl-inp forPassword ${currentPasswordError ? 'errored' : ''}`}
            type={passwordMode === 'hide' ? 'password' : 'text'}
            name="currPass"
            onChange={handleCurrentPasswordChange}
            onKeyDown={onKeyDown}
            value={currentPassword}
            ref={inputCurrentPassRef}
            id="currPass"
          />
          {
            hasForgotPassUI
              ? (
                <button
                  type="button"
                  className="forgot-password-ui"
                  onClick={forgotPassFunc}
                >
                  {t('Forgot Password?')}
                </button>
              ) : null
          }
          {allowPasswordShow && (
            <button
              className="xcl-togglePass-btn noTranslate"
              type="button"
              onClick={() => handlePasswordModeToggle(inputCurrentPassRef)}
            >
              <span className="xcl-togglePass-icon">
                {passwordMode === 'hide' ? <IconEyeSlashFilled className="forHide" /> : <IconEyeFilled className="forShow" />}
              </span>
            </button>
          )}
          {currentPasswordError && hasErrorMessage && <ErrorAlert message={currentPasswordError} />}
        </div>
      )}
      <div className="xcl-field-wr forPassword" id="passwordField">
        <label className="xcl-lbl" htmlFor="suPassword">{t(passwordLabel)}</label>
        <input
          className={`xcl-inp forPassword ${requiredPassError ? 'errored' : ''}`}
          type={passwordMode === 'hide' ? 'password' : 'text'}
          name="pass"
          onChange={handlePasswordChange}
          onKeyDown={onKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={password}
          id="suPassword"
          autoComplete={autoComplete}
          ref={inputPassRef}
          aria-label={requiredPassError && t(Texts.PASS_FIELD_REQUIRED)}
          placeholder={customTexts.inputPlaceholder || (isOnboardingFlow && regularRuleMessages?.characterCount ? t(`At least ${regularRuleMessages?.characterCount}`) : '')} // A/B Test: signupOnboardingIV
        />
        {disclaimer && (
          <div className="forDisclaimer">
            {disclaimer}
          </div>
        )}
        {allowPasswordShow && (
          <button
            className="xcl-togglePass-btn noTranslate"
            type="button"
            onClick={() => handlePasswordModeToggle(inputPassRef)}
          >
            <span className="xcl-togglePass-icon">
              {passwordMode === 'hide'
                ? (
                  <>
                    <IconEyeSlashFilled className="forHide" />
                    <span className="xcl-togglePass-tooltip">{t(Texts.SHOW_PASSWORD)}</span>
                  </>
                )
                : (
                  <>
                    <IconEyeFilled className="forShow" />
                    <span className="xcl-togglePass-tooltip">{t(Texts.HIDE_PASSWORD)}</span>
                  </>
                )}
            </span>
          </button>
        )}
        {
          (showRegularRequirementsList && (
            <RequirementsList
              checkReuse={false}
              securePassDescText={customTexts.regularRequirementDesc || requirementDescText}
              securePassErrors={passwordError || regularPassErrors}
              getSecureRuleClass={getSecureRuleClass}
              getSecureRuleColor={getSecureRuleColor}
              secureRules={passwordErrorMessage || regularRuleMessages}
              hasSecureTextIcon={true}
              passwordSecurityRules={passwordSecurityRules}
            />
          ))
        }
        <div
          role="alert"
          id="passwordFieldMessage"
          className={`xcl-lbl-err ${password !== '' && requiredPassError && hasErrorMessage ? 'isVisible' : null}`}
        >
          {requiredPassError}
        </div>
      </div>
      {
        (secure && secureDisplay === 'top' && showSecureRequirementsList) ? (
          renderSecureRequirementsList()
        ) : null
      }
      {
        includeConfirm ? (
          <div className="xcl-field-wr forPassword" id="passwordConfField">
            <label className="xcl-lbl" htmlFor="suPasswordConf">{t(passwordConfirmationLabel)}</label>
            <input
              className={`xcl-inp forPassword ${confirmPassError ? 'errored' : ''}`}
              type={passwordMode === 'hide' ? 'password' : 'text'}
              name="confirmPass"
              onChange={handleConfirmPasswordChange}
              onKeyDown={onKeyDown}
              value={confirmPassword}
              id="suPasswordConf"
              ref={inputConfirmPassRef}
            />
            <button
              className="xcl-togglePass-btn noTranslate"
              type="button"
              onClick={() => handlePasswordModeToggle(inputConfirmPassRef)}
            >
              <span className="xcl-togglePass-icon">
                {passwordMode === 'hide'
                  ? (
                    <>
                      <IconEyeSlashFilled className="forHide" />
                      <span className="xcl-togglePass-tooltip">{t(Texts.SHOW_PASSWORD)}</span>
                    </>
                  )
                  : (
                    <>
                      <IconEyeFilled className="forShow" />
                      <span className="xcl-togglePass-tooltip">{t(Texts.HIDE_PASSWORD)}</span>
                    </>
                  )}
              </span>
            </button>
            <div role="alert" className={`xcl-lbl-err ${confirmPassError && hasErrorMessage ? ' isVisible' : null}`} id="passwordConfFieldMessage">
              {confirmPassError && hasErrorMessage ? confirmPassError : null}
            </div>
          </div>
        ) : null
      }
      {
        (secure && secureDisplay !== 'top' && showSecureRequirementsList) ? (
          renderSecureRequirementsList()
        ) : null
      }
    </ScPassword>
  );
});

Password.propTypes = {
  hasForgotPassUI: bool,
  forgotPassFunc: func,
  getCurrentPasswordValue: func,
  passwordLabel: string,
  passwordConfirmationLabel: string,
  secure: bool,
  secureDisplay: string,
  toggleSecureDisplay: bool,
  includeConfirm: bool,
  checkReuse: bool,
  onKeyDown: func,
  passwordErrorServer: string,
  resetPasswordError: func,
  setSecurePaswordError: func,
  callbackConfirmPassError: func,
  userType: string,
  requestCurrentPassword: bool,
  onChange: func,
  sendValueToParent: bool,
  allowPasswordShow: bool,
  clearInitialOnFocus: bool,
  initialValue: string,
  onPasswordInputBlur: func,
  hasErrorMessage: bool,
  hasSecureTextIcon: bool,
  className: string,
  disclaimer: string,
  autoComplete: string,
  onPasswordValidate: func,
  isOnboardingFlow: bool,
  customTexts: shape({
    regularRequirementDesc: string,
    secureRequirementDesc: string,
    inputPlaceholder: string
  }),
  hideNeutralRules: bool
};

Password.defaultProps = {
  hasForgotPassUI: false,
  forgotPassFunc: f => f,
  getCurrentPasswordValue: f => f,
  passwordLabel: Texts.PASSWORD_TEXT,
  passwordConfirmationLabel: Texts.CONF_PASSWORD_TEXT,
  secure: false,
  secureDisplay: '',
  toggleSecureDisplay: false,
  includeConfirm: false,
  checkReuse: false,
  onKeyDown: f => f,
  passwordErrorServer: '',
  resetPasswordError: f => f,
  setSecurePaswordError: f => f,
  callbackConfirmPassError: f => f,
  userType: 'formuser',
  requestCurrentPassword: false,
  onChange: f => f,
  sendValueToParent: false,
  allowPasswordShow: true,
  clearInitialOnFocus: false,
  initialValue: '',
  onPasswordInputBlur: f => f,
  hasErrorMessage: true,
  hasSecureTextIcon: false,
  className: '',
  disclaimer: null,
  autoComplete: null,
  onPasswordValidate: f => f,
  isOnboardingFlow: false,
  customTexts: {
    regularRequirementDesc: '',
    secureRequirementDesc: '',
    inputPlaceholder: ''
  },
  hideNeutralRules: false
};

export default Password;
