export const langCodes = {
  tr: 'tr-TR',
  en: 'en-US',
  es: 'es-ES',
  pt: 'pt-PT',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  bg: 'bg-BG',
  sr: 'sr-BA',
  hu: 'hu-HU',
  fi: 'fi-FI',
  ka: 'ka-GE',
  nl: 'nl-NL',
  uk: 'uk-UA',
  ua: 'uk-UA',
  id: 'id-ID',
  ko: 'ko-KR',
  ar: 'ar-AR',
  ja: 'ja-JP',
  pl: 'pl-PL',
  ca: 'ca-ES',
  sv: 'sv-SE',
  no: 'no-NO',
  da: 'da-DA',
  ro: 'ro-RO',
  ru: 'ru-RU',
  zh: 'zh-CN',
  'zh-CN': 'zh-CN',
  'zh-HK': 'zh-HK',
  af: 'af-ZA',
  he: 'he-IL'
};

export const partialTranslations = {
  'animal-shelter': path => /(jotform.com|jform.co.kr|jotform.pro)(\/){1}(animal-shelter){1}/g.test(path)
};

/**
 * Returns Jotform translation files suffix (locale/locale_SUFFIX.js)
 * @param {string} localeCode - lang-[country] (en-US, pt-BR, en)
 * @return {string} A value from langCodes object.
 */
export const getLangFileSuffix = localeCode => {
  const lang = localeCode.split('-')[0];
  return langCodes[localeCode] || langCodes[lang];
};

export const DEFAULT_LANGUAGE = 'en-US';
