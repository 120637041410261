import React, { useState } from 'react';
import {
  array, bool, func, object
} from 'prop-types';

import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import SocialsContainer from './SocialsContainer';
import ModalHeader from './ModalHeader';

import { verifyAndDelete } from '../../utils/helper';
import ErrorMessage from './ErrorMessage';

const DeleteAccountModalStep2 = ({
  socialLogins, onAppleLoginClick, onFBLoginClick, onGoogleLoginClick, onMicrosoftLoginClick, onSalesforceLoginClick, thereSocialError, user, handleStep
}) => {
  const [isPasswordErrored, setIsPasswordErrored] = useState(false);
  const [password, setPassword] = useState('');

  const handleChange = e => {
    const { target: { value } } = e;
    setPassword(value);
    setIsPasswordErrored(false);
  };

  const handleDeleteAccount = async e => {
    e.preventDefault(); // no refresh after submit
    const { credentials: { username } } = user;

    if (password) {
      try {
        const result = await verifyAndDelete({ type: 'email', username, password });
        if (typeof result === 'object' && result.token) {
          setIsPasswordErrored(!result.status);
        } else { // TODO: remove this condition when api is merged
          setIsPasswordErrored(!result);
        }
        handleStep(3);
      } catch {
        setIsPasswordErrored(true);
      }
    }
  };

  return (
    <div className="verify-account-container">
      <ModalHeader />
      {socialLogins.length > 0 && (
        <>
          <SocialsContainer
            socialLogins={socialLogins}
            onAppleLoginClick={onAppleLoginClick}
            onFBLoginClick={onFBLoginClick}
            onGoogleLoginClick={onGoogleLoginClick}
            onMicrosoftLoginClick={onMicrosoftLoginClick}
            onSalesforceLoginClick={onSalesforceLoginClick}
            user={user}
          />
          {thereSocialError && (
            <ErrorMessage text={Texts.YOU_COULD_NOT_BE_VERIFIED} />
          )}
          <div className='xcl-divider verify-modal-divider'>
            <span>OR</span>
          </div>
        </>
      )}
      <div className='password-section'>
        <span>{t(Texts.PLEASE_ENTER_YOUR_PASSWORD)}</span>
        <form className="verify-form" onSubmit={handleDeleteAccount}>
          <input type="password" onChange={handleChange} />
          {isPasswordErrored && (
            <ErrorMessage text={Texts.YOU_HAVE_ENTERED_YOUR_CURRENT_PASSWORD_WRONG} />
          )}
          <button disabled={password.length === 0} className='submit-button' type="submit">{t(Texts.VERIFY_DELETE)}</button>
        </form>
      </div>
    </div>
  );
};

DeleteAccountModalStep2.propTypes = {
  socialLogins: array.isRequired,
  user: object.isRequired,
  onAppleLoginClick: func,
  onFBLoginClick: func,
  onGoogleLoginClick: func,
  onMicrosoftLoginClick: func,
  onSalesforceLoginClick: func,
  thereSocialError: bool,
  handleStep: func
};

DeleteAccountModalStep2.defaultProps = {
  onAppleLoginClick: () => {},
  onFBLoginClick: () => {},
  onGoogleLoginClick: () => {},
  onMicrosoftLoginClick: () => {},
  onSalesforceLoginClick: () => {},
  thereSocialError: false,
  handleStep: () => {}
};

export default DeleteAccountModalStep2;
