export const persistentKeyList = [
  /^.*?VariantCode$/,
  'gocardlessPayload',
  'stripeCode',
  'DIGEST_ACTION_LSK',
  'leadFlowBannerAutoVisible',
  'messageHistory',
  /^AUTO_DELETE_INFO_POPUP_KEY_.*?$/,
  'firstVisitDone',
  'formCopilotModalSeen',
  /^limitWarningSeen_.*?$/,
  'notification-lal-autovisible',
  'notification-up-to-fifty-percent-discount-autovisible',
  'notification-hubspot-milestone',
  'notification-trysign',
  'notification-fomo-a1-third-autovisible',
  /^uxrSurvey-.*?$/,
  'notification-hipaa-enforcement',
  'ncseen',
  'notification-cent-store-recommendation',
  'notification-cent-app-recommendation',
  'stripeCode',
  /contentOfQuestion_.*?$/,
  'actionKanbanCount',
  'actionReportCount',
  'lastShownTimeDraftWarning',
  /^AUTO_DELETE_INFO_POPUP_KEY_.*?$/,
  'subscriptionsColumnList',
  'invoiceColumnList',
  'ttmLanguage',
  'boxnet_auth_token',
  'discardSalesforceEarlyAccessModal',
  'discardSalesforceRatingModal',
  'AIChat',
  'FormCopilotProps',
  /^whats_new_.*?$/
];
